<template>
  <div class="bodyWhatsapp">
    <div class="background-green"></div>
    <div class="main-container">
      <div class="left-container">

        <!--header -->
        <div class="header">
          <div class="user-img">
            <img class="dp" src="https://www.codewithfaraz.com/InstaPic.png" alt="">
          </div>
          <div class="nav-icons">
            <li><i class="fa-solid fa-users"></i></li>
            <li><i class="fa-solid fa-message">
              </i></li>
            <li><i class="fa-solid fa-ellipsis-vertical"></i></li>
          </div>
        </div>


        <!--notification -->
        <div class="notif-box">
          <i class="fa-solid fa-bell-slash"></i>
          <div class="notif-text">
            <p>Get Notified of New Messages</p>
            <a href="#">Turn on Desktop Notifications ›</a>
          </div>
          <i class="fa-solid fa-xmark"></i>
        </div>

        <!--search-container -->
        <div class="search-container">
          <div class="divInput">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input type="text" placeholder="Search or start new chat   ">
          </div>

        </div>


        <!--chats -->
        <div class="chat-list">
          <div class="chat-box active" v-for="chat in chats" :key="chat.uid" @click="selectedChat(chat)">
            <div class="img-box">
              <img class="img-cover" :src="chat.photo" alt="">
            </div>
            <div class="chat-details">
              <div class="text-head">
                <h4>{{ chat.name }}</h4>
                <p class="time unread">{{ getLastMessageTime(chat) }}</p>
              </div>
              <div class="text-message">
                <p>{{ getLastMessageText(chat) }}</p>
                <b>1</b>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="right-container" v-if="chat_selected && chat_selected.messages && chat_selected.messages.length">
        <!--header -->
        <div class="header">
          <div class="img-text">
            <div class="user-img">
              <img class="dp" :src=chat_selected.photo alt="">
            </div>
            <h4>{{ chat_selected.name }}<br><span>Online</span></h4>
          </div>
          <div class="nav-icons">
            <li><i class="fa-solid fa-magnifying-glass"></i></li>
            <li><i class="fa-solid fa-ellipsis-vertical"></i></li>
          </div>
        </div>

        <!--chat-container -->
        <div class="chat-container">
          <div v-for="message in chat_selected.messages" :key="message.uid">
            <div class="message-box my-message" v-if="message.sender === 'Me'">
              <p>{{ message.text }}<br><span>{{ formatToTime(message.time) }}</span></p>
            </div>
            <div class="message-box friend-message" v-else>
              <p>{{ message.text }}<br><span>{{ formatToTime(message.time) }}</span></p>
            </div>
          </div>
        </div>

        <!--input-bottom -->
        <div class="chatbox-input">
          <i class="fa-regular fa-face-grin"></i>
          <i class="fa-sharp fa-solid fa-paperclip"></i>
          <input type="text" v-model="newMessageText" @keyup.enter="sendMessage" placeholder="Type a message">
          <i class="fa-solid fa-microphone"></i>
        </div>
      </div>
      <div class="chat_empty padding" v-else>
        <img src="https://i.ibb.co/WGDzNR2/a.png" alt="please keep phone connnected">
        <h2>Keep your phone connected</h2>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
      </div>
    </div>
  </div>
</template>
  
<script>
import { db, collection, getDocs, query, addDoc, serverTimestamp, onSnapshot } from '@/firebase.js';
export default {
  name: 'WhatsappPage',
  async mounted() {
    try {
      const chatsCollection = collection(db, 'chats');
      const chatSnapshot = await getDocs(chatsCollection);

      const chatsData = [];
      for (const chatDoc of chatSnapshot.docs) {
        let chat = chatDoc.data();

        // Pegando as mensagens para o chat atual
        const messagesQuery = query(collection(chatDoc.ref, 'messages'));
        const messagesSnapshot = await getDocs(messagesQuery);

        chat.messages = messagesSnapshot.docs.map(msgDoc => msgDoc.data());

        chatsData.push(chat);
      }

      this.chats = chatsData;
    } catch (error) {
      console.error("Erro ao buscar chats:", error);
    }
  },
  data() {
    return {
      chat_selected: null,
      chats: [],
      newMessageText: "",
      unsubscribe: null,
    }
  },
  methods: {
    formatToTime(timestamp) {

      if (timestamp && timestamp.toDate) {
        const date = timestamp.toDate(); // Convertendo o objeto Timestamp do Firestore para um objeto Date do JavaScript
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      }
    },
    formatDate(timestamp) {
      if (timestamp && timestamp.toDate) {
        const now = new Date();
        const messageDate = timestamp.toDate();  // Convertendo o objeto Timestamp do Firestore para um objeto Date do JavaScript
        const diffInSeconds = (now - messageDate) / 1000;  // Diferença em segundos
        const diffInMinutes = diffInSeconds / 60;
        const diffInHours = diffInMinutes / 60;
        const diffInDays = diffInHours / 24;
        // Se a mensagem foi enviada hoje
        if (now.toDateString() === messageDate.toDateString()) {
          if (diffInMinutes < 1) {
            return "agora";
          } else if (diffInMinutes === 1) {
            return "há 1 minuto";
          } else if (diffInMinutes < 10) {
            return `há ${Math.round(diffInMinutes)} minutos`;
          } else {
            return messageDate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
          }
        }

        // Se a mensagem foi enviada ontem
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        if (yesterday.toDateString() === messageDate.toDateString()) {
          return "ontem";
        }

        // Se a mensagem foi enviada até 6 dias atrás
        if (diffInDays <= 6) {
          const daysOfWeek = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"];
          return daysOfWeek[messageDate.getDay()];
        }

        // Se a mensagem foi enviada há mais de 6 dias
        return messageDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
      } else {
        console.log("Timestamp ainda não está disponível");
      }
    },
    async sendMessage() {
      if (!this.chat_selected || !this.newMessageText) return;

      const messageData = {
        text: this.newMessageText,
        time: serverTimestamp(), // ou use o Firebase Server Timestamp
        sender: "Me" // Ajuste conforme necessário
      };

      try {
        const messagesCollection = collection(db, 'chats', this.chat_selected.uid, 'messages');
        try {
          await addDoc(messagesCollection, messageData);
        } catch (error) {
          console.error("Erro ao enviar mensagem:", error);
          // Remova a mensagem da UI ou notifique o usuário
        }

        // Limpar o campo de input após enviar
        this.newMessageText = '';
      } catch (error) {
        console.error("Erro ao enviar mensagem:", error);
      }
    },
    selectedChat(chat) {
      console.log("chat")
      console.log(chat)
      this.chat_selected = chat
      // Certifique-se de desregistrar qualquer listener anterior
      if (this.unsubscribe) {
        this.unsubscribe = null;
      }

      const messagesCollection = collection(db, 'chats', chat.uid, 'messages');

      // Configurar o listener
      this.unsubscribe = onSnapshot(messagesCollection, (snapshot) => {
        let messages = [];
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            messages.push(change.doc.data());
          }
          // Você pode adicionar lógica para 'modified' e 'removed' se necessário
        });
        this.chat_selected.messages = messages;
      });
    },
    getLastMessageTime(chat) {
      if (chat.messages && chat.messages.length) {
        const timestamp = chat.messages[chat.messages.length - 1].time;
        return this.formatDate(timestamp);
      }
      return '';
    },
    getLastMessageText(chat) {
      if (chat.messages && chat.messages.length) {
        return chat.messages[chat.messages.length - 1].text;
      }
      return '';
    }
  }
}
</script>
  
<style>
::-webkit-scrollbar {
  width: 5px;
  /* Largura da barra de rolagem vertical */
  height: 10px;
  /* Altura da barra de rolagem horizontal */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Cor do controle deslizante */
  border-radius: 4px;
  /* Arredondamento dos cantos */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Cor quando o mouse passa por cima */
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.bodyWhatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ccc;
}

.background-green {
  position: absolute;
  top: 0;
  width: 100%;
  height: 20%;
  background-color: #009688;
}

.main-container {
  position: relative;
  width: 80%;
  max-width: 100%;
  height: calc(100vh - 40px);
  background: #fff;
  display: flex;
  box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.5), 0px 2px 5px 0 rgba(0, 0, 0, 0.6);
}

.main-container .left-container {
  display: flex;
  flex-direction: column;
  min-width: 360px;
  width: 30%;
  height: 100%;
  flex: 30%;
  background: #fff;
  overflow: hidden;
}

.main-container .right-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #e5ddd5;
}

.main-container .right-container::before {
  width: 100%;
  height: 100%;
  background: url(https://camo.githubusercontent.com/854a93c27d64274c4f8f5a0b6ec36ee1d053cfcd934eac6c63bed9eaef9764bd/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67);
  opacity: 0.5;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #ededed;
  padding: 0 15px;
  justify-content: space-between;
}

.user-img {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}

.dp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.nav-icons {
  display: flex;
  justify-content: flex-end;
  padding-left: 110px;
}

.nav-icons li {
  list-style: none;
  display: flex;
  cursor: pointer;
  color: #51585c;
  margin-left: 22px;
  font-size: 18px;
}

.notif-box {
  display: flex;
  width: 100%;
  height: 70px;
  background: #76daff;
  align-items: center;
  font-size: 0.8em;
  text-decoration: none;
  justify-content: space-around;
  flex-direction: row;
}

.notif-box i {
  background: #fff;
  padding: 10px;
  width: 42px;
  height: auto;
  font-size: 20px;
  border-radius: 55%;
  cursor: pointer;
  color: #76daff;
}

.notif-box .fa-xmark {
  text-align: center;
  background: #76daff;
  color: #fff;
}


.notif-text a {
  text-decoration: none;
  color: #333;
  font-size: 0.9em;
}

.search-container {
  width: 100%;
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}

.search-container .divInput {
  display: flex;
  width: 90%;
  height: 45px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  align-items: center;
}

.search-container .divInput input {
  width: 100%;
  outline: none;
  border: none;
  background: #fff;
  padding: 10px;
  height: 100%;
  border-radius: 10px;
  font-size: 12px;
  margin: 10px
}

.search-container .divInput i {
  color: #bbb;
  width: 20px;
  font-size: 0.8em;
}

.chat-list {
  position: relative;
  height: calc(100% - 170px);
  overflow-y: auto;
}

.chat-list .chat-box {
  position: relative;
  width: 100%;
  display: flex;
  /*   justify-content: center; */
  align-items: center;
  cursor: pointer;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.chat-list .chat-box .img-box {
  position: relative;
  width: 55px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
}

.chat-list .chat-box .chat-details {
  width: 100%;
  margin-left: 10px;
}

.chat-list .chat-box .chat-details .text-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.chat-list .chat-box .chat-details .text-head h4 {
  font-size: 1.1em;
  font-weight: 600;
  color: #000;
}

.chat-list .chat-box .chat-details .text-head .time {
  font-size: 0.8em;
  color: #aaa;
}

.chat-list .chat-box .chat-details .text-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-list .chat-box .chat-details .text-message p {
  color: #aaa;
  font-size: 0.9em;
  overflow: hidden;
  max-height: 20px;
}

img {
  width: 100%;
  object-fit: cover;
}

.chat-list .chat-box .chat-details .text-message b {
  background: #06e744;
  color: #fff;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  /*   text-align: center; */
  font-size: 0.8em;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-list .active {
  background: #ebebeb;
}

.chat-list .chat-box:hover {
  background: #f5f5f5;
}

.chat-list .chat-box .chat-details .text-head .unread {
  color: #06e744;
}


/* right-container */

.chat_empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  background: #f8f9fa;
}

.chat_empty img {
  border-radius: 999px;
  height: 15rem;
  width: 15rem;
  margin-bottom: 1.5rem;
}

.chat_empty h2 {
  font-size: 1.5rem;
  color: #333;
  font-weight: 200;
  margin-bottom: 1rem;
  text-align: center;
}

.right-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-container .header .img-text .user-img .dp {
  position: relative;
  left: 0px;
  width: 40px;
  height: auto;
  overflow: hidden;
  object-fit: cover;
}

.right-container .header .img-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.right-container .header .img-text h4 {
  font-weight: 500;
  line-height: 1.2em;
  margin-left: 15px;
}

.right-container .header .img-text h4 span {
  font-size: 0.8em;
  color: #555;
}

.right-container .header .nav-icons {
  position: relative;
  margin-right: 0px;
  /*   padding: 5px; */
}

.right-container .header .nav-icons i {
  padding: 10px;
}

.chat-container {
  width: 100%;
  height: calc(100% - 120px);
  padding: 25px;
  padding-right: 15px;
  overflow-y: auto;
}

.message-box {
  position: relative;
  display: flex;
  width: 100%;
  margin: 5px 0;
}

.message-box p {
  position: relative;
  right: 0;
  text-align: right;
  max-width: 90%;
  padding: 12px;
  background: #dcf8c6;
  border-radius: 10px;
  font-size: 0.9em;
}

.message-box.my-message p::before {
  content: '';
  position: absolute;
  top: 0;
  right: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, #dcf8c6 0%, #dcf8c6 50%, transparent 50%, transparent);
}

.message-box p span {
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  opacity: 0.5;
}

.my-message {
  justify-content: flex-end;
}

.friend-message p {
  background: #fff;
}

.friend-message {
  justify-content: flex-start;

}

.chat-container .my-message i {
  color: yellow;
}

.message-box.friend-message::before {
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(225deg, #fff 0%, #fff 50%, transparent 50%, transparent);
}

.chatbox-input {
  position: relative;
  width: 100%;
  height: 60px;
  background: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbox-input i {
  cursor: pointer;
  font-size: 1.8em;
  color: #515855;
}

.chatbox-input i:nth-child(1) {
  margin: 15px;
}

.chatbox-input i:last-child {
  margin-right: 25px;
}

.chatbox-input input {
  position: relative;
  width: 90%;
  margin: 0 20px;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1em;
  border: none;
  outline: none;
}
</style>
  