import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, query, addDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA5GofnHQXKqbMRclv2LGV1W4sYr-d4lS0",
    authDomain: "qrcodetest-d0231.firebaseapp.com",
    projectId: "qrcodetest-d0231",
    storageBucket: "qrcodetest-d0231.appspot.com",
    messagingSenderId: "624931894938",
    appId: "1:624931894938:web:8a953ba2cb8b76aa20d123"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, getDocs, query, addDoc, serverTimestamp, onSnapshot };
