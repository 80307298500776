import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/whatsapp'
const routes = [
    {
      name: 'Home',
      path: '/',
      component: Home,
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;
